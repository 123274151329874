import { configureStore } from '@reduxjs/toolkit'
import myEmployees from "../pages/my-employees/redux/reducer";
import mySafeguards from "../pages/safeguards/redux/reducer";
import myDashboard from "../pages/dashboard/redux/reducer";
import mySettings from '../pages/my-settings/redux/reducer'

// const store = createStore(storeReducers);
const store = configureStore({
  reducer: {
    myDashboard,
    myEmployees,
    mySafeguards,
    mySettings
  }
})


export default store;