import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withAdvancesSummary(Component) {
	return (props) => {

		const ghostingCompanies = useSelector(state => state.myDashboard.ghostingCompanies)
		const ghostingStatus = useSelector(state => state.myDashboard.ghostingStatus  )
		const dispatch = useDispatch()
		const ghostingSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			ghostingCompanies,
      ghostingStatus
		}
		const passThruProps = {...props, ghostingSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withAdvancesSummary